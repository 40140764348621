// body
body {
  overflow-x: hidden;
}

// navbar light
.navbar-light {
  background-color: var(--bg-light) !important;
}

.navbar.scrolled.navbar-light {
  background-color: white !important;
}

// navbar dark
.navbar.scrolled.navbar-dark, .navbar.navbar-toggled-show.navbar-dark {
  background-color: var(--mevi-black) !important;
}

.navbar.scrolled.navbar-dark .navbar-nav .nav-link, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .nav-link {
  color: var(--navbar-dark-text);
}

.navbar.navbar-light .navbar-nav .show > .dropdown-toggle, .navbar.navbar-dark .navbar-nav .show > .dropdown-toggle {
  color: var(--mevi-green);
}

.navbar.navbar-dark  .navbar-nav .nav-link:hover, .navbar.scrolled.navbar-dark .navbar-nav .nav-link:hover, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--mevi-green);
}

.navbar.navbar-light  .navbar-nav .nav-link:hover, .navbar.scrolled.navbar-light .navbar-nav .nav-link:hover, .navbar.navbar-toggled-show.navbar-light .navbar-nav .nav-link:hover {
  color: var(--mevi-green);
}

.navbar.navbar-dark .navbar-nav .dropdown-item, .navbar.scrolled.navbar-dark .navbar-nav .dropdown-item {
  color: var(--mevi-charcoal);
}

.navbar.navbar-toggled-show.navbar-dark .navbar-nav .dropdown-item {
  color: var(--navbar-dark-text);
}

.navbar.navbar-dark .navbar-nav .dropdown-item:hover, .navbar.scrolled.navbar-dark .navbar-nav .dropdown-item:hover, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .dropdown-item:hover {
  color: var(--mevi-green);
}

.navbar.scrolled.navbar-dark .navbar-nav .nav-link.dropdown-toggle:after, .navbar.navbar-toggled-show.navbar-dark .navbar-nav .nav-link.dropdown-toggle:after {
  @include dropdown-arrow-image($white, 90deg);
}

.navbar.scrolled.navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]), .navbar.navbar-toggled-show.navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]) {
  stroke: #FFFFFF;
}
.navbar.scrolled.navbar-dark .navbar-toggler svg [fill]:not([fill="none"]), .navbar.navbar-toggled-show.navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]) {
  fill: #FFFFFF;
}

// gradients
.mevi-bg-gradient-dark {
  background: linear-gradient(to right, #162A33, #4C4C4C) !important;
}

.pricing-blob {
  width: 95%;
  height: 75% !important;
}

@media only screen and (max-width: 575px) {
  .pricing-blob {
    width: 100% !important;
    height: 65%  !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pricing-blob {
    width: 100% !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-blob {
    width: 85% !important;
  }
}

// forms
.mevi-email-input {
  min-width: 338px;
}

// mark
mark{
  background-image: linear-gradient(120deg,rgba($primary-2,1) 0%,rgba($primary-2,1) 100%);
}

// text
.emphasis {
  text-decoration: underline;
  font-weight: 600;
}

.font-125 {
  font-size: 1.25rem;
}

.font-145 {
  font-size: 1.45rem;
}

@media only screen and (max-width: 767px) {
  .font-145 {
    font-size: 1.05rem;
  }
}

// lead-home
@media only screen and (min-width: 1200px) {
  .lead-home {
    font-size: 22px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lead-home {
    font-size: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lead-home {
    font-size: 19px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lead-home {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 575px) {
  .lead-home {
    font-size: 16px !important;
  }
}

// cursor
.cursor-pointer {
  cursor: pointer;
}

// button
.media-button {
  padding: 19px !important;
  margin-bottom: 20px;
}

// modal
.modal-vimeo {
  max-width: 640px;
  max-height: 360;
}

.download-pitch {
  color: white;
  margin: 0 auto 15px auto;

  svg {
    width: 24px;
  }
}

// adjustmemnts
.max-width-250 {
  max-width: 250px;
}

.mt-badge {
  margin-top: 30px;
}

.h-32 {
  height: 32px;
}

.my-16 {
  margin: 16px 0;
}

.additional-price {
  line-height: 19.2px;
  margin: 0 0 0 5px;
}

.pricing-no-check-div {
  margin-left: 32px;
}

.bg-black {
  background-color: black !important;
}

.bg-mevi-green {
  background-color: #00A896 !important;
}

.bg-mevi-green-light {
  background-color: #CCEEEA !important;
}

.bg-mevi-green-medium {
  background-color: #66CBC0 !important;
}

.bg-mevi-yellow {
  background-color: #FFE600 !important;
}

svg.bg-mevi-green [stroke]:not([stroke="none"]) {
  stroke: #00A896;
}

svg.bg-mevi-green-light [stroke]:not([stroke="none"]) {
  stroke: #CCEEEA;
}

svg.bg-mevi-green-light [fill]:not([fill="none"]) {
  fill: #CCEEEA;
}

svg.bg-mevi-green-medium [stroke]:not([stroke="none"]) {
  stroke: #66CBC0;
}

svg.bg-mevi-green-medium [fill]:not([fill="none"]) {
  fill: #66CBC0;
}

.border-bottom-grey-2 {
  border-bottom: var(--mevi-grey) 2px solid;
}

.border-bottom-primary-2 {
  border-bottom: var(--mevi-green) 2px solid;
}

.btn-outline-primary.disabled:hover, .btn-outline-primary:disabled:hover {
  background-color: transparent;
}

.btn-primary:hover {
  background-color: var(--mevi-green-medium);
}

@media (min-width: 1200px) {
  .buyers-blob, .sellers-blob {
    right: 4vw !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 992px) {
  .buyers-blob, .sellers-blob {
    bottom: -15vw !important;
  }
}
@media (max-width: 576px) {
  .msc-content {
    text-align: center;
    img {
      max-height: 400px;
    }
  }
}

// theme.css replacements
.display-1 {
  font-size: 6rem;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}
@media (max-width: 576px) {
  .display-2 {
    font-size: calc(1.475rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.0rem;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}
@media (max-width: 576px) {
  .display-3 {
    font-size: calc(1.375rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.25;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}
@media (max-width: 576px) {
  .display-4 {
    font-size: calc(1.275rem + 2.7vw);
  }
}

// Optimization for sm/xs screens
@media (max-width: 720px) {
  .column-reverse-sm {
    flex-direction: column-reverse;
  }
  .h5-sm {
    font-weight: 600;
    font-size: 19px;
    padding-bottom: 10px;
  }
}

.peace-of-mind-image-div {
  display: flex;
  align-items: flex-end;

  @media (max-width:720px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 720px) {
    width: 50%;
  }
}

.esignature {
  height: 150px;
}

.esignature-images {
  margin: 5px;
}

// Watch the Video buttons
.agents-video-btn {
  width: 250px;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    position: relative;
  }
}

.agents-top-btns {
  @media (min-width: 576px) {
    padding-bottom: 10px;
  }
}

.play-btn {
  width: 30px;
  height: 25px;
}

// Bottom CTA

.btm-txt-lg-agents {
  @media (min-width: 950px) {
    font-size: 50px;
  }
}

.msc-btm-img {
  @media (min-width: 768px) {
    max-height: 800px;
  }
}

// Hubspot Newsletter Form
.hs-button {
  background-color: #00a896;
  border-color: #00a896;
  box-shadow: none;
  color: #FFFFFF;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 2px solid transparent;
  padding: 0.625rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.hs-button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}
.hs-button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}
.hs-button, select {
  text-transform: none;
}
.hs-button, input {
  overflow: visible;
}
.hs-button:hover {
  color: #fff;
  background-color: #66CBC0;
  border-color: #00684c;
}
.hs-button:focus, .btn-primary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(38, 170, 135, 0.5);
}

.hs-input {
  height: calc(1.5em + 1.375rem + 2px);
  display: block;
  padding: 0.6875rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #555A64;
  border: 1px solid #EAEDF2;
  border-radius: 0.1875rem;
  flex-grow: 1 !important;
  align-self: center;

  @media (min-width: 576px) {
    margin-right: 0.5rem !important;
    margin-bottom: 0 !important;
  }
  @media (max-width: 730px) {
    margin-right: 10px;
  }
}

.hs-form {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
  position: relative;
}

.hs_error_rollup {
  display: none;
}
.hs-error-msgs {
  position: absolute;
}

@media(max-width: 765px) {
  .mtop {
    margin-top: 35px;
  }
}

.rounded-circle-bullet {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: flex-end;

  .bullet {
    height: 32px;
    padding-top: 4px;
  }
}
