// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Change the hex values below to alter the main color scheme.

$primary:       #00a896;
$primary-2:     #66CBC0;
$primary-3:     #162a33;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

:root {
  --mevi-green: #00A896;
  --mevi-green-medium: #66CBC0;
  --mevi-green-light: #CCEEEA;
  --mevi-yellow: #FFE600;
  --mevi-black: #162A33;
  --mevi-charcoal: #4C4C4C;
  --mevi-grey: #78849E;
  --mevi-grey-med: #EEEEEE;
  --mevi-grey-light: #FBF9F9;
  --mevi-orange: #F8B05C;
  --mevi-purple: #6E188E;
  --mevi-blue: #5773FF;
  --mevi-red: #F04141;
  --mevi-font: 'Nunito', sans-serif;
  --bg-light: #F7F9FC;
  --navbar-dark-text: #E1E3E4;
}